body::-webkit-scrollbar {
    display: none;
}

/* our custom primary button style */
.btn {
    @apply text-xl inline-block bg-button py-2 px-4  rounded text-white font-semibold  hover:bg-hoverButton transition duration-300;
}

.tab {
    @apply flex items-center  border-2 border-primary p-1 lg:px-4 lg:py-2 rounded-md text-primary text-center font-semibold  hover:bg-primary hover:text-white transition  transform active:scale-90;
}

.tabActive {
    @apply flex items-center bg-button p-1 lg:px-4 lg:py-2 rounded-md text-white text-center font-semibold  hover:bg-hoverButton transition  transform active:scale-90;
}

.btn-secondary {
    @apply bg-white p-2 rounded border-2 border-primary text-primary  text-center font-semibold   transition hover:bg-opacity-20 hover:bg-primary transform active:scale-90;
}

.loader {
    @apply w-4 h-4 border-2  loadingBorder rounded-full animate-spin mx-auto;
}

/* general typrography utility className */

@media (min-width: 640px) {
    .titleLeading {
        line-height: 60px;
    }
}
@media (min-width: 1024px) {
    .titleLeading {
        line-height: 90px;
    }
}

.divider {
    @apply border-t;
}
.userProfileDivider {
    @apply m-auto mt-2 bg-gray-200 h-[1px] max-w-[62.5rem];
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.479);
    z-index: 1;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.addEmails {
    animation: textEnter 40ms ease;
}

.toggleCircle {
    width: 45%;
    height: 100%;
}

.loadingBorder {
    border-right: 2px solid rgba(0, 0, 0, 0.2);
}
